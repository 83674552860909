import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const NoThankYou = () => {
    return (
        <div className='page'>
            <h6 style={{ fontWeight: '600', marginTop: '100px', textAlign: 'center', color: '#BB1A1B', marginBottom: '30px' }}>Thank you very much. We will be send sms and contact you shortly</h6>
            {<Link to='/'>
                <Button variant='danger w-50' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none' }}>
                    Home
                </Button>
            </Link>}
        </div>
    )
}

export default NoThankYou