import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap'
import { useState } from 'react';
import axios from 'axios'

const SignatureConfirmation = ({
  confirmsignYes,
  setConfirmsignYes,
  cellNumber,
  agentCode,
  forceNumber,
  armsOfService,
  rankTitle,
  fullNames,
  surname,
  IDNumber,
  mainID,
  altCellNumber,
  landlineNumber,
  emailAddress,
  streetNumber,
  streetName,
  complexName,
  unitNumber,
  suburb,
  town,
  postalCode,
  unitName,
  unitSwitchBoardNumber,
  maritalStatus,
  monthlyPremium,
  spouseTitle,
  spouseFullNames,
  spouseSurname,
  spouseID,
  spouseIdFile,
  childrenAmount,
  childTitle,
  childFullNames,
  childSurname,
  childID,
  childTitle2,
  childFullNames2,
  childSurname2,
  childID2,
  childTitle3,
  childFullNames3,
  childSurname3,
  childID3,
  childTitle4,
  childFullNames4,
  childSurname4,
  childID4,
  childTitle5,
  childFullNames5,
  childSurname5,
  childID5,
  childTitle6,
  childFullNames6,
  childSurname6,
  childID6,
  childrenIds,
  pbTitle,
  pbFullNames,
  pbSurname,
  pbID,
  pbCellNumber,
  pbIdData,
  setPbIdData,
  nlTitle,
  nlFullNames,
  nlSurname,
  nlIDNumber,
  nlCellNumber,
  nlIdData,
  setNlIdData,
  paymentMethod,
  base64Data,
  bankingDetailsDO,
  idParams,
  dpip,
  dpipDetails,
  fppo,
  fppoDetails,
  closeDpipFppo,
  closeDpipFppoDetails,
  pbLandline,
  pbRelationship,
  nlLandline,
  nlRelationship,
  sourceIncome,
  sourceIncomeDetails,
  mainAge,
  spouseAge,
  childAge,
  childAge2,
  childAge3,
  childAge4,
  childAge5,
  childAge6,
  pbAge,
  nlAge,
  spouseCell,
  agsb,
  agsbwide,
  rhcb,
  cgsb,
  asb,
  other,
  otherDetails,
  rhcbwide,
  cgsbwide,
  asbwide,
  otherwide,
  otherDetailswide,
  marketingConsent,
  memberRelation,
  relationship,
  name,
  relationSurname,
  title,
  dateOfBirth,
  age,
  benefit,
  premium,
  memberRelation2,
  relationship2,
  name2,
  relationSurname2,
  title2,
  dateOfBirth2,
  age2,
  benefit2,
  premium2,
  memberRelation3,
  relationship3,
  name3,
  relationSurname3,
  title3,
  dateOfBirth3,
  age3,
  benefit3,
  premium3,
  memberRelation4,
  relationship4,
  name4,
  relationSurname4,
  title4,
  dateOfBirth4,
  age4,
  benefit4,
  premium4,
  memberRelation5,
  relationship5,
  name5,
  relationSurname5,
  title5,
  dateOfBirth5,
  age5,
  benefit5,
  premium5,
  memberRelation6,
  relationship6,
  name6,
  relationSurname6,
  title6,
  dateOfBirth6,
  age6,
  benefit6,
  premium6,
  memberRelation7,
  relationship7,
  name7,
  relationSurname7,
  title7,
  dateOfBirth7,
  age7,
  benefit7,
  premium7,
  memberRelation8,
  relationship8,
  name8,
  relationSurname8,
  title8,
  dateOfBirth8,
  age8,
  benefit8,
  premium8,
  extendedMonthlyPremium,
  extendedPaymentMethod,
  otherExtendedText,
  extendedBankingDetailsDO
}) => {
  const [showNext, setShowNext] = useState(false);
  const [error, setError] = useState('');

  const requiredSubmitted = () => {
    if (confirmsignYes !== 'Yes') {
      var config = {
        method: 'post',
        url: 'https://apiv2.msgl.ink/',
        headers: {
          'content-type': 'application/json'
        },
        data: {
          agentCode,
          forceNumber,
          armsOfService,
          rankTitle,
          fullNames,
          surname,
          IDNumber,
          mainID,
          mainAge,
          cellNumber,
          altCellNumber,
          landlineNumber,
          emailAddress,
          streetNumber,
          streetName,
          complexName,
          unitNumber,
          suburb,
          town,
          postalCode,
          unitName,
          unitSwitchBoardNumber,
          maritalStatus,
          monthlyPremium,
          spouseTitle,
          spouseFullNames,
          spouseSurname,
          spouseID,
          spouseIdFile,
          childrenAmount,
          childTitle,
          childFullNames,
          childSurname,
          childID,
          childTitle2,
          childFullNames2,
          childSurname2,
          childID2,
          childTitle3,
          childFullNames3,
          childSurname3,
          childID3,
          childTitle4,
          childFullNames4,
          childSurname4,
          childID4,
          childTitle5,
          childFullNames5,
          childSurname5,
          childID5,
          childTitle6,
          childFullNames6,
          childSurname6,
          childID6,
          childrenIds,
          pbTitle,
          pbFullNames,
          pbSurname,
          pbID,
          pbCellNumber,
          pbIdData,
          setPbIdData,
          nlTitle,
          nlFullNames,
          nlSurname,
          nlIDNumber,
          nlCellNumber,
          nlIdData,
          setNlIdData,
          paymentMethod,
          base64Data,
          bankingDetailsDO,
          idParams,
          pbLandline,
          pbRelationship,
          nlLandline,
          nlRelationship,
          sourceIncome,
          sourceIncomeDetails,
          spouseAge,
          childAge,
          childAge2,
          childAge3,
          childAge4,
          childAge5,
          childAge6,
          pbAge,
          nlAge,
          spouseCell,
          marketingConsent,
          agsb,
          agsbwide,
          rhcb,
          cgsb,
          asb,
          other,
          otherDetails,
          memberRelation,
          relationship,
          name,
          relationSurname,
          title,
          dateOfBirth,
          age,
          benefit,
          premium,
          memberRelation2,
          relationship2,
          name2,
          relationSurname2,
          title2,
          dateOfBirth2,
          age2,
          benefit2,
          premium2,
          memberRelation3,
          relationship3,
          name3,
          relationSurname3,
          title3,
          dateOfBirth3,
          age3,
          benefit3,
          premium3,
          memberRelation4,
          relationship4,
          name4,
          relationSurname4,
          title4,
          dateOfBirth4,
          age4,
          benefit4,
          premium4,
          memberRelation5,
          relationship5,
          name5,
          relationSurname5,
          title5,
          dateOfBirth5,
          age5,
          benefit5,
          premium5,
          memberRelation6,
          relationship6,
          name6,
          relationSurname6,
          title6,
          dateOfBirth6,
          age6,
          benefit6,
          premium6,
          memberRelation7,
          relationship7,
          name7,
          relationSurname7,
          title7,
          dateOfBirth7,
          age7,
          benefit7,
          premium7,
          memberRelation8,
          relationship8,
          name8,
          relationSurname8,
          title8,
          dateOfBirth8,
          age8,
          benefit8,
          premium8,
          extendedMonthlyPremium,
          extendedPaymentMethod,
          otherExtendedText,
          extendedBankingDetailsDO,
          dpip,
          dpipDetails,
          fppo,
          fppoDetails,
          closeDpipFppo,
          closeDpipFppoDetails,
          rhcbwide,
          cgsbwide,
          asbwide,
          otherwide,
          otherDetailswide,
          confirmsignYes,
          SourceSite: "shieldapp.co.za"
        }
      }
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (confirmsignYes === 'Choose select the option') {
      setError('You have not made a selection')
    } else if (confirmsignYes === 'Yes') {
      setShowNext(true)
      setError('Please check that all info is correct then click next')
    } else if (confirmsignYes === 'No') {
      setShowNext(true)
      setError('Please check that all info is correct then click next')
    } else {
      setError('You have not made a selection')
    }
  }

  return (
    <div className="page">
      <h6 style={{ fontWeight: '600', marginTop: '30px', textAlign: 'left', marginBottom: '25px' }}>*Please select one the following signing options</h6>
      <Form className='Form' style={{ float: 'left', textAlign: 'left', width: '100%' }} >
        <Form.Check
          type="radio"
          id="sign"
          label="Sign now"
          name="group1"
          onChange={(e) => setConfirmsignYes("Yes")}
        /> <br />
        <Form.Check
          type="radio"
          label={'Send a signing link to the following mobile number - ' + cellNumber}
          id="link"
          name="group1"
          onChange={(e) => setConfirmsignYes("No")}
        />
      </Form>

      <div style={{ textAlign: 'center', padding: '5vw' }}><h6 style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>{error}</h6></div>

      <div style={{ textAlign: 'center' }}>
        <Link to='/3'>
          <Button variant='secondary w-25' style={{ fontWeight: '600', background: '#D0D0D0', border: 'none', float: 'left', marginLeft: '20vw', marginTop: '2vh' }}>BACK</Button>
        </Link>

        {showNext && <Link to={confirmsignYes === 'Yes' ? '/8' : '/nothankyou'}>
          <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginRight: '20vw', marginBottom: '10vh', marginTop: '2vh' }}>NEXT</Button>
        </Link>}
        {!showNext && <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginRight: '20vw', marginBottom: '10vh', marginTop: '2vh' }} onClick={requiredSubmitted} >Submit</Button>}
      </div>
    </div>
  )
}
export default SignatureConfirmation